.map {
    text-align: center;
}

.map svg {
    width:80% !important;
    margin: 0;
    background:white;
    max-height: calc(100vh - 140px);
}

svg .selectable, .selectable path {
    opacity: 1 !important;
    cursor: pointer;
}

.selectable:hover, .selectable:hover path{
    opacity: 0.9 !important;
}

.selectable.selected, .selectable.selected path{
    fill:#F44336 !important;
    opacity: 0.6 !important;
}