.rbc-event, .rbc-selected {
  /* background-color: #093 !important; */
}

.rbc-calendar {
  height: calc(100% - 75px);
} 

.rbc-events-container .rbc-event {
  bottom: auto !important;
  top: auto !important;
  left: auto !important;
  right:auto !important;
  position: relative !important;

  height:auto !important;
  width: 100% !important;

  margin-bottom: 10px;
}

.rbc-time-content > .rbc-time-gutter {
  display: none;
}

.rbc-time-column .rbc-timeslot-group {
  display: none;
}

.rbc-time-content .rbc-row-content {
  display: none;
}

.rbc-time-header-gutter {
  display:none;
}

.rbc-event {
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 5px;
  background-color: #3174ad;
  border-radius: 0px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.rbc-day-slot .rbc-event-content, .rbc-day-slot .rbc-event-label, .rbc-event .rbc-event-content {
  font-size: 12px;
}

.rbc-show-more {
  font-size:12px;
  color: #404040;
  background: #f3f3f3;
  padding: 2px;
  border: none;
  text-decoration: none;
}
.rbc-day-slot .rbc-event {
  border: none;
}


.rbc-toolbar button {
  border-radius: 0;
}

.rbc-current-time-indicator {
  background:none;
}

.rbc-today {
  background-color: #ddd;
}

.rbc-toolbar button {
  cursor: pointer;
  margin-top:1px;
}

.rbc-today {
  background-color: #d4d4d4;
}


@media only screen and (max-width: 550px) {
  .rbc-toolbar {
    text-align: center;
  }
  
  .rbc-toolbar > span {
    width:100%;
    margin: 1px 0;
  }
}

