body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ReactModalPortal {
  z-index: 10;
  position: relative;
}

ul {
  margin:0;
}

#checkboxesWrapper::-webkit-scrollbar
{
	width: 10px;
  background-color: #ccc;
}

#checkboxesWrapper::-webkit-scrollbar-thumb
{
  background-color: rgb(0, 153, 51);
}

.App {
  overflow: hidden;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}